import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/about1.png";
import Tilt from "react-parallax-tilt";
import { AiFillGithub} from "react-icons/ai";
import { FaLinkedinIn, FaGoogle } from 'react-icons/fa';

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            👋 Hi! I am Aryesh Shivmare, a TYBSC Computer Science student at Mumbai University with a passion for Java full-stack development. 
              <br />
              <br />I have experience in developing web applications using 
              <i>
                <b className="purple"> Java, Spring Boot, and React JS⚛️, </b>
              </i>
              I enjoy creating seamless and dynamic web applications. 💻✨
              <br />
              <br />
               I’ve worked on exciting projects like a chat application and an AI chatbot named <i><b className="purple"> 'Apna AI,' </b></i>where I integrated various technologies to enhance user experiences🚀
              
              <br />
              <br />
              I’m always eager to learn and apply new skills, and I’m on the lookout for opportunities to contribute to  <b className="purple">innovative projects and grow as a web developer🌟</b>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Aryesh404"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/aryesh-shivmare-31a887315/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                  <a
                     href="mailto:aryeshshivmare508@gmail.com" // Replace with your actual email
                     target="_blank"
                     rel="noreferrer"
                     className="icon-colour home-social-icons"
                  >
                  <FaGoogle />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
