import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { FaLink } from "react-icons/fa"; // Import a new icon for the deployed project

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>

        {/* Use d-flex and justify-content-between to align buttons to the left and right */}
        <div className="d-flex justify-content-between w-100">
          <Button variant="primary" href={props.ghlink} target="_blank">
            <BsGithub /> &nbsp;
            {props.isBlog ? "Blog" : "GitHub"}
          </Button>

          {/* If the component contains a Demo link and it's not a Blog, render the Demo button */}
          {!props.isBlog && props.demoLink && (
            <Button variant="primary" href={props.demoLink} target="_blank">
              <CgWebsite /> &nbsp; Demo
            </Button>
          )}

          {/* If the component contains a deployed project link, render the Deployed button */}
          {props.deployedLink && (
            <Button variant="success" href={props.deployedLink} target="_blank">
              <FaLink /> &nbsp; View
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default ProjectCards;
