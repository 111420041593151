import React from "react";
import { Col, Row } from "react-bootstrap";

import {
 
  DiReact,
  DiJava,
  
} from "react-icons/di";
import { SiSpringboot , SiMysql , SiMongodb} from "react-icons/si";


function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      
      
      
      <Col xs={4} md={2} className="tech-icons">
        
      
        <DiJava />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        
      
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        
      
        <SiSpringboot />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        
      
        <SiMysql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        
      
        <SiMongodb />
      </Col>
      
      
         
    </Row>
  );
}

export default Techstack;
