import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Aryesh Shivmare </span> 
            from <span className="purple">Mumbai, India.</span>
            <br /> I am a Final year student pursuing BSC Computer Science at Mumbai University.
            <br />
            
            <br />
            <br />
            Apart from coding, I am deeply passionate about spirituality, especially devotional service to Lord Krishna. I find peace and inspiration in reading spiritual books and traveling to holy places.
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Devotional Service to Lord Shri Krishna🙏
            </li>
            <li className="about-activity">
              <ImPointRight /> Reading Spiritual Books📚
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling to Holy Places🧳
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "You have a right to perform your prescribed duty, but you are not entitled to the fruits of action."{" "}
          </p>
          <footer className="blockquote-footer">Lord Krishna</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
